import useRouterState from "./useRouterState"
import { getDataOrigin, getIDPOrigin } from '../utils/toolbox'

const useAssetBaseUri = ({
  idps,
  accounts,
  forceCookieInUri,
}) => {

  const { routerState } = useRouterState()
  const { widget } = routerState

  const idp = Object.values(idps)[0]
//sumerkumawat changed for getting image from AWS by server API URL skumawat
  return `${getDataOrigin(idp)}/c/${Object.values(accounts)[0].cookie}`

}

export default useAssetBaseUri
